<template>
  <div>
    <treeselect :options="options" v-model="value" :multiple="multiple" id="type" placeholder="请选择..." v-on:input="change()">
      <div slot="value-label" slot-scope="{ node }">
        {{ node.raw.label }}
      </div>

      <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }"
        :class="labelClassName">
        {{ node.raw.label }}
        <span v-if="shouldShowCount" :class="countClassName">({{ count }})</span>
      </label>

    </treeselect>
  </div>
</template>

<script>
  export default {
    name: "TreeSelect",
    props: {
      select: {
        type: Array
      },
      nameKey: "name",
      valueKey: "value",
      datas: {
        type: Array
      },
    },
    data: () => ({
      multiple: true,
      value: [],
      options: []
    }),

    created() {
      this.resoveOptions();
    },
    mounted() {
      this.resoveOptions();
    },
    methods: {
      change() {
        this.$emit("update:select", this.value)
      },
      updateSelect(array) {
        this.value = array;
      },
      resoveOptions() {
        let that = this;
        console.info("resovle  treeselect options:", this.data);
        if (this.datas != undefined && this.datas.length > 0) {
          this.datas.forEach(item => {
            that.options.push({
              id: item[that.valueKey],
              label: item[that.nameKey]
            });
          })
        }
      }
    },
    watch: {
      datas: function (newVal, oldVal) {
        this.resoveOptions();
      }
    },
  };
</script>

<style>
  .vue-treeselect-helper-hide {
    display: none
  }

  .vue-treeselect-helper-zoom-effect-off {
    transform: none !important
  }

  @-webkit-keyframes vue-treeselect-animation-fade-in {
    0% {
      opacity: 0
    }
  }

  @keyframes vue-treeselect-animation-fade-in {
    0% {
      opacity: 0
    }
  }

  @-webkit-keyframes vue-treeselect-animation-bounce {

    0%,
    to {
      transform: scale(0)
    }

    50% {
      transform: scale(1)
    }
  }

  @keyframes vue-treeselect-animation-bounce {

    0%,
    to {
      transform: scale(0)
    }

    50% {
      transform: scale(1)
    }
  }

  @-webkit-keyframes vue-treeselect-animation-rotate {
    to {
      transform: rotate(1turn)
    }
  }

  @keyframes vue-treeselect-animation-rotate {
    to {
      transform: rotate(1turn)
    }
  }

  .vue-treeselect__multi-value-item--transition-enter-active,
  .vue-treeselect__multi-value-item--transition-leave-active {
    transition-duration: .2s;
    transition-property: transform, opacity
  }

  .vue-treeselect__multi-value-item--transition-enter-active {
    transition-timing-function: cubic-bezier(.075, .82, .165, 1)
  }

  .vue-treeselect__multi-value-item--transition-leave-active {
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    position: absolute
  }

  .vue-treeselect__multi-value-item--transition-enter,
  .vue-treeselect__multi-value-item--transition-leave-to {
    transform: scale(.7);
    opacity: 0
  }

  .vue-treeselect__multi-value-item--transition-move {
    transition: transform .2s cubic-bezier(.165, .84, .44, 1)
  }

  .vue-treeselect {
    position: relative;
    text-align: left
  }

  [dir=rtl] .vue-treeselect {
    text-align: right
  }

  .vue-treeselect div,
  .vue-treeselect span {
    box-sizing: border-box
  }

  .vue-treeselect svg {
    fill: currentColor
  }

  .vue-treeselect__control {
    padding-left: 5px;
    padding-right: 5px;
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 36px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background: #fff;
    transition-duration: .2s;
    transition-property: border-color, box-shadow, width, height, background-color, opacity;
    transition-timing-function: cubic-bezier(.215, .61, .355, 1)
  }

  .vue-treeselect:not(.vue-treeselect--disabled):not(.vue-treeselect--focused) .vue-treeselect__control:hover {
    border-color: #cfcfcf
  }

  .vue-treeselect--focused:not(.vue-treeselect--open) .vue-treeselect__control {
    border-color: #039be5;
    box-shadow: 0 0 0 3px rgba(3, 155, 229, .1)
  }

  .vue-treeselect--disabled .vue-treeselect__control {
    background-color: #f9f9f9
  }

  .vue-treeselect--open .vue-treeselect__control {
    border-color: #cfcfcf
  }

  .vue-treeselect--open.vue-treeselect--open-below .vue-treeselect__control {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
  }

  .vue-treeselect--open.vue-treeselect--open-above .vue-treeselect__control {
    border-top-left-radius: 0;
    border-top-right-radius: 0
  }

  .vue-treeselect__multi-value,
  .vue-treeselect__value-container {
    width: 100%;
    vertical-align: middle
  }

  .vue-treeselect__value-container {
    display: table-cell;
    position: relative
  }

  .vue-treeselect--searchable:not(.vue-treeselect--disabled) .vue-treeselect__value-container {
    cursor: text
  }

  .vue-treeselect__multi-value {
    display: inline-block
  }

  .vue-treeselect--has-value .vue-treeselect__multi-value {
    margin-bottom: 5px
  }

  .vue-treeselect__placeholder,
  .vue-treeselect__single-value {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 5px;
    padding-right: 5px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    line-height: 34px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none
  }

  .vue-treeselect__placeholder {
    color: #bdbdbd
  }

  .vue-treeselect__single-value {
    color: #333
  }

  .vue-treeselect--focused.vue-treeselect--searchable .vue-treeselect__single-value {
    color: #bdbdbd
  }

  .vue-treeselect--disabled .vue-treeselect__single-value {
    position: static
  }

  .vue-treeselect__multi-value-item-container {
    display: inline-block;
    padding-top: 5px;
    padding-right: 5px;
    vertical-align: top
  }

  [dir=rtl] .vue-treeselect__multi-value-item-container {
    padding-right: 0;
    padding-left: 5px
  }

  .vue-treeselect__multi-value-item {
    display: inline-table;
    padding: 2px 0;
    border: 1px solid transparent;
    border-radius: 2px;
    font-size: 12px;
    vertical-align: top
  }

  .vue-treeselect:not(.vue-treeselect--disabled) .vue-treeselect__multi-value-item:not(.vue-treeselect__multi-value-item-disabled):hover .vue-treeselect__multi-value-item:not(.vue-treeselect__multi-value-item-new) .vue-treeselect__multi-value-item:not(.vue-treeselect__multi-value-item-new):hover,
  .vue-treeselect__multi-value-item {
    cursor: pointer;
    background: #e3f2fd;
    color: #039be5
  }

  .vue-treeselect__multi-value-item.vue-treeselect__multi-value-item-disabled {
    cursor: default;
    background: #f5f5f5;
    color: #757575
  }

  .vue-treeselect--disabled .vue-treeselect__multi-value-item {
    cursor: default;
    background: #fff;
    border-color: #e5e5e5;
    color: #555
  }

  .vue-treeselect__multi-value-item.vue-treeselect__multi-value-item-new,
  .vue-treeselect__multi-value-item.vue-treeselect__multi-value-item-new:hover {
    background: #e8f5e9
  }

  .vue-treeselect__multi-value-label,
  .vue-treeselect__value-remove {
    display: table-cell;
    padding: 0 5px;
    vertical-align: middle
  }

  .vue-treeselect__value-remove {
    color: #039be5;
    padding-left: 5px;
    border-left: 1px solid #fff;
    line-height: 0
  }

  [dir=rtl] .vue-treeselect__value-remove {
    border-left: 0 none;
    border-right: 1px solid #fff
  }

  .vue-treeselect__multi-value-item:hover .vue-treeselect__value-remove {
    color: #e53935
  }

  .vue-treeselect--disabled .vue-treeselect__value-remove,
  .vue-treeselect__multi-value-item-disabled .vue-treeselect__value-remove {
    display: none
  }

  .vue-treeselect__value-remove>svg {
    width: 6px;
    height: 6px
  }

  .vue-treeselect__multi-value-label {
    padding-right: 5px;
    white-space: pre-line;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
  }

  .vue-treeselect__limit-tip {
    display: inline-block;
    padding-top: 5px;
    padding-right: 5px;
    vertical-align: top
  }

  [dir=rtl] .vue-treeselect__limit-tip {
    padding-right: 0;
    padding-left: 5px
  }

  .vue-treeselect__limit-tip-text {
    cursor: default;
    display: block;
    margin: 2px 0;
    padding: 1px 0;
    color: #bdbdbd;
    font-size: 12px;
    font-weight: 600
  }

  .vue-treeselect__input-container {
    display: block;
    max-width: 100%;
    outline: none
  }

  .vue-treeselect--single .vue-treeselect__input-container {
    font-size: inherit;
    height: 100%
  }

  .vue-treeselect--multi .vue-treeselect__input-container {
    display: inline-block;
    font-size: 12px;
    vertical-align: top
  }

  .vue-treeselect--searchable .vue-treeselect__input-container {
    padding-left: 5px;
    padding-right: 5px
  }

  .vue-treeselect--searchable.vue-treeselect--multi.vue-treeselect--has-value .vue-treeselect__input-container {
    padding-top: 5px;
    padding-left: 0
  }

  [dir=rtl] .vue-treeselect--searchable.vue-treeselect--multi.vue-treeselect--has-value .vue-treeselect__input-container {
    padding-left: 5px;
    padding-right: 0
  }

  .vue-treeselect--disabled .vue-treeselect__input-container {
    display: none
  }

  .vue-treeselect__input,
  .vue-treeselect__sizer {
    margin: 0;
    line-height: inherit;
    font-family: inherit;
    font-size: inherit
  }

  .vue-treeselect__input {
    max-width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
    box-sizing: content-box;
    box-shadow: none;
    background: none transparent;
    line-height: 1;
    vertical-align: middle
  }

  .vue-treeselect__input::-ms-clear {
    display: none
  }

  .vue-treeselect--single .vue-treeselect__input {
    width: 100%;
    height: 100%
  }

  .vue-treeselect--multi .vue-treeselect__input {
    padding-top: 3px;
    padding-bottom: 3px
  }

  .vue-treeselect--has-value .vue-treeselect__input {
    line-height: inherit;
    vertical-align: top
  }

  .vue-treeselect__sizer {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    height: 0;
    overflow: scroll;
    white-space: pre
  }

  .vue-treeselect__x-container {
    display: table-cell;
    vertical-align: middle;
    width: 20px;
    text-align: center;
    line-height: 0;
    cursor: pointer;
    color: #ccc;
    -webkit-animation: vue-treeselect-animation-fade-in .2s cubic-bezier(.075, .82, .165, 1);
    animation: vue-treeselect-animation-fade-in .2s cubic-bezier(.075, .82, .165, 1)
  }

  .vue-treeselect__x-container:hover {
    color: #e53935
  }

  .vue-treeselect__x {
    width: 8px;
    height: 8px
  }

  .vue-treeselect__control-arrow-container {
    display: table-cell;
    vertical-align: middle;
    width: 20px;
    text-align: center;
    line-height: 0;
    cursor: pointer
  }

  .vue-treeselect--disabled .vue-treeselect__control-arrow-container {
    cursor: default
  }

  .vue-treeselect__control-arrow {
    width: 9px;
    height: 9px;
    color: #ccc
  }

  .vue-treeselect:not(.vue-treeselect--disabled) .vue-treeselect__control-arrow-container:hover .vue-treeselect__control-arrow {
    color: #616161
  }

  .vue-treeselect--disabled .vue-treeselect__control-arrow {
    opacity: .35
  }

  .vue-treeselect__control-arrow--rotated {
    transform: rotate(180deg)
  }

  .vue-treeselect__menu-container {
    position: absolute;
    left: 0;
    width: 100%;
    overflow: visible;
    transition: 0s
  }

  .vue-treeselect--open-below:not(.vue-treeselect--append-to-body) .vue-treeselect__menu-container {
    top: 100%
  }

  .vue-treeselect--open-above:not(.vue-treeselect--append-to-body) .vue-treeselect__menu-container {
    bottom: 100%
  }

  .vue-treeselect__menu {
    cursor: default;
    padding-top: 5px;
    padding-bottom: 5px;
    display: block;
    position: absolute;
    overflow-x: hidden;
    overflow-y: auto;
    width: auto;
    border: 1px solid #cfcfcf;
    background: #fff;
    line-height: 180%;
    -webkit-overflow-scrolling: touch
  }

  .vue-treeselect--open-below .vue-treeselect__menu {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    top: 0;
    margin-top: -1px;
    border-top-color: #f2f2f2;
    box-shadow: 0 1px 0 rgba(0, 0, 0, .06)
  }

  .vue-treeselect--open-above .vue-treeselect__menu {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    bottom: 0;
    margin-bottom: -1px;
    border-bottom-color: #f2f2f2
  }

  .vue-treeselect__indent-level-0 .vue-treeselect__option {
    padding-left: 5px
  }

  [dir=rtl] .vue-treeselect__indent-level-0 .vue-treeselect__option {
    padding-left: 5px;
    padding-right: 5px
  }

  .vue-treeselect__indent-level-0 .vue-treeselect__tip {
    padding-left: 25px
  }

  [dir=rtl] .vue-treeselect__indent-level-0 .vue-treeselect__tip {
    padding-left: 5px;
    padding-right: 25px
  }

  .vue-treeselect__indent-level-1 .vue-treeselect__option {
    padding-left: 25px
  }

  [dir=rtl] .vue-treeselect__indent-level-1 .vue-treeselect__option {
    padding-left: 5px;
    padding-right: 25px
  }

  .vue-treeselect__indent-level-1 .vue-treeselect__tip {
    padding-left: 45px
  }

  [dir=rtl] .vue-treeselect__indent-level-1 .vue-treeselect__tip {
    padding-left: 5px;
    padding-right: 45px
  }

  .vue-treeselect__indent-level-2 .vue-treeselect__option {
    padding-left: 45px
  }

  [dir=rtl] .vue-treeselect__indent-level-2 .vue-treeselect__option {
    padding-left: 5px;
    padding-right: 45px
  }

  .vue-treeselect__indent-level-2 .vue-treeselect__tip {
    padding-left: 65px
  }

  [dir=rtl] .vue-treeselect__indent-level-2 .vue-treeselect__tip {
    padding-left: 5px;
    padding-right: 65px
  }

  .vue-treeselect__indent-level-3 .vue-treeselect__option {
    padding-left: 65px
  }

  [dir=rtl] .vue-treeselect__indent-level-3 .vue-treeselect__option {
    padding-left: 5px;
    padding-right: 65px
  }

  .vue-treeselect__indent-level-3 .vue-treeselect__tip {
    padding-left: 85px
  }

  [dir=rtl] .vue-treeselect__indent-level-3 .vue-treeselect__tip {
    padding-left: 5px;
    padding-right: 85px
  }

  .vue-treeselect__indent-level-4 .vue-treeselect__option {
    padding-left: 85px
  }

  [dir=rtl] .vue-treeselect__indent-level-4 .vue-treeselect__option {
    padding-left: 5px;
    padding-right: 85px
  }

  .vue-treeselect__indent-level-4 .vue-treeselect__tip {
    padding-left: 105px
  }

  [dir=rtl] .vue-treeselect__indent-level-4 .vue-treeselect__tip {
    padding-left: 5px;
    padding-right: 105px
  }

  .vue-treeselect__indent-level-5 .vue-treeselect__option {
    padding-left: 105px
  }

  [dir=rtl] .vue-treeselect__indent-level-5 .vue-treeselect__option {
    padding-left: 5px;
    padding-right: 105px
  }

  .vue-treeselect__indent-level-5 .vue-treeselect__tip {
    padding-left: 125px
  }

  [dir=rtl] .vue-treeselect__indent-level-5 .vue-treeselect__tip {
    padding-left: 5px;
    padding-right: 125px
  }

  .vue-treeselect__indent-level-6 .vue-treeselect__option {
    padding-left: 125px
  }

  [dir=rtl] .vue-treeselect__indent-level-6 .vue-treeselect__option {
    padding-left: 5px;
    padding-right: 125px
  }

  .vue-treeselect__indent-level-6 .vue-treeselect__tip {
    padding-left: 145px
  }

  [dir=rtl] .vue-treeselect__indent-level-6 .vue-treeselect__tip {
    padding-left: 5px;
    padding-right: 145px
  }

  .vue-treeselect__indent-level-7 .vue-treeselect__option {
    padding-left: 145px
  }

  [dir=rtl] .vue-treeselect__indent-level-7 .vue-treeselect__option {
    padding-left: 5px;
    padding-right: 145px
  }

  .vue-treeselect__indent-level-7 .vue-treeselect__tip {
    padding-left: 165px
  }

  [dir=rtl] .vue-treeselect__indent-level-7 .vue-treeselect__tip {
    padding-left: 5px;
    padding-right: 165px
  }

  .vue-treeselect__indent-level-8 .vue-treeselect__option {
    padding-left: 165px
  }

  [dir=rtl] .vue-treeselect__indent-level-8 .vue-treeselect__option {
    padding-left: 5px;
    padding-right: 165px
  }

  .vue-treeselect__indent-level-8 .vue-treeselect__tip {
    padding-left: 185px
  }

  [dir=rtl] .vue-treeselect__indent-level-8 .vue-treeselect__tip {
    padding-left: 5px;
    padding-right: 185px
  }

  .vue-treeselect__option {
    padding-left: 5px;
    padding-right: 5px;
    display: table;
    table-layout: fixed;
    width: 100%
  }

  .vue-treeselect__option--highlight {
    background: #f5f5f5
  }

  .vue-treeselect--single .vue-treeselect__option--selected {
    background: #e3f2fd;
    font-weight: 600
  }

  .vue-treeselect--single .vue-treeselect__option--selected:hover {
    background: #e3f2fd
  }

  .vue-treeselect__option--hide {
    display: none
  }

  .vue-treeselect__option-arrow-container,
  .vue-treeselect__option-arrow-placeholder {
    display: table-cell;
    vertical-align: middle;
    width: 20px;
    text-align: center;
    line-height: 0
  }

  .vue-treeselect__option-arrow-container {
    cursor: pointer
  }

  .vue-treeselect__option-arrow {
    display: inline-block;
    width: 9px;
    height: 9px;
    color: #ccc;
    vertical-align: middle;
    transition: transform .2s cubic-bezier(.19, 1, .22, 1);
    transform: rotate(-90deg)
  }

  [dir=rtl] .vue-treeselect__option-arrow {
    transform: rotate(90deg)
  }

  .vue-treeselect--branch-nodes-disabled .vue-treeselect__option:hover .vue-treeselect__option-arrow,
  .vue-treeselect__option-arrow-container:hover .vue-treeselect__option-arrow {
    color: #616161
  }

  .vue-treeselect__option-arrow--rotated,
  [dir=rtl] .vue-treeselect__option-arrow--rotated {
    transform: rotate(0)
  }

  .vue-treeselect__option-arrow--rotated.vue-treeselect__option-arrow--prepare-enter {
    transform: rotate(-90deg) !important
  }

  [dir=rtl] .vue-treeselect__option-arrow--rotated.vue-treeselect__option-arrow--prepare-enter {
    transform: rotate(90deg) !important
  }

  .vue-treeselect__label-container {
    display: table-cell;
    vertical-align: middle;
    cursor: pointer;
    display: table;
    width: 100%;
    table-layout: fixed;
    color: inherit
  }

  .vue-treeselect__option--disabled .vue-treeselect__label-container {
    cursor: not-allowed;
    color: rgba(0, 0, 0, .25)
  }

  .vue-treeselect__checkbox-container {
    display: table-cell;
    width: 20px;
    min-width: 20px;
    height: 100%;
    text-align: center;
    vertical-align: middle
  }

  .vue-treeselect__checkbox {
    display: block;
    margin: auto;
    width: 12px;
    height: 12px;
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
    position: relative;
    transition: all .2s cubic-bezier(.075, .82, .165, 1)
  }

  .vue-treeselect__check-mark,
  .vue-treeselect__minus-mark {
    display: block;
    position: absolute;
    left: 1px;
    top: 1px;
    background-repeat: no-repeat;
    opacity: 0;
    transition: all .2s ease
  }

  .vue-treeselect__minus-mark {
    width: 8px;
    height: 8px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAIAgMAAAC5YVYYAAAACVBMVEUAAAD///////9zeKVjAAAAAnRSTlMAuLMp9oYAAAAPSURBVAjXY4CDrJUgBAMAGaECJ9dz3BAAAAAASUVORK5CYII=);
    background-size: 8px 8px
  }

  @media (-webkit-min-device-pixel-ratio: 1.5),
  (min-resolution: 1.5dppx) {
    .vue-treeselect__minus-mark {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAgMAAABinRfyAAAADFBMVEUAAAD///////////84wDuoAAAAA3RSTlMAyTzPIdReAAAAGUlEQVQI12PAD+b///+Nof7//79gAsLFCwAx/w4blADeeQAAAABJRU5ErkJggg==)
    }
  }

  @media (-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 192dpi) {
    .vue-treeselect__minus-mark {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAgMAAABinRfyAAAADFBMVEUAAAD///////////84wDuoAAAAA3RSTlMAyTzPIdReAAAAGUlEQVQI12PAD+b///+Nof7//79gAsLFCwAx/w4blADeeQAAAABJRU5ErkJggg==)
    }
  }

  @media (-webkit-min-device-pixel-ratio: 3),
  (min-resolution: 288dpi) {
    .vue-treeselect__minus-mark {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAD1BMVEUAAAD///////////////+PQt5oAAAABHRSTlMAy2EFIuWxUgAAACRJREFUGNNjGBBgJOICBY7KDCoucODEAJSAS6FwUJShGjAQAADBPRGrK2/FhgAAAABJRU5ErkJggg==)
    }
  }

  .vue-treeselect__checkbox--indeterminate>.vue-treeselect__minus-mark {
    opacity: 1
  }

  .vue-treeselect__checkbox--disabled .vue-treeselect__minus-mark {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAIAgMAAAC5YVYYAAAACVBMVEUAAADi4uLh4eHOxeSRAAAAAnRSTlMAuLMp9oYAAAAPSURBVAjXY4CDrJUgBAMAGaECJ9dz3BAAAAAASUVORK5CYII=)
  }

  @media (-webkit-min-device-pixel-ratio: 1.5),
  (min-resolution: 1.5dppx) {
    .vue-treeselect__checkbox--disabled .vue-treeselect__minus-mark {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAgMAAABinRfyAAAADFBMVEUAAADi4uLi4uLh4eE5RQaIAAAAA3RSTlMAyTzPIdReAAAAGUlEQVQI12PAD+b///+Nof7//79gAsLFCwAx/w4blADeeQAAAABJRU5ErkJggg==)
    }
  }

  @media (-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 192dpi) {
    .vue-treeselect__checkbox--disabled .vue-treeselect__minus-mark {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAgMAAABinRfyAAAADFBMVEUAAADi4uLi4uLh4eE5RQaIAAAAA3RSTlMAyTzPIdReAAAAGUlEQVQI12PAD+b///+Nof7//79gAsLFCwAx/w4blADeeQAAAABJRU5ErkJggg==)
    }
  }

  @media (-webkit-min-device-pixel-ratio: 3),
  (min-resolution: 288dpi) {
    .vue-treeselect__checkbox--disabled .vue-treeselect__minus-mark {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAD1BMVEUAAADh4eHg4ODNzc3h4eEYfw2wAAAABHRSTlMAy2EFIuWxUgAAACRJREFUGNNjGBBgJOICBY7KDCoucODEAJSAS6FwUJShGjAQAADBPRGrK2/FhgAAAABJRU5ErkJggg==)
    }
  }

  .vue-treeselect__check-mark {
    width: 8px;
    height: 8px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAMAAADz0U65AAAAQlBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////8IX9KGAAAAFXRSTlMA8u24NxILB+Tawb6jiH1zRz0xIQIIP3GUAAAAMklEQVQI1y3FtQEAMQDDQD+EGbz/qkEVOpyEOP6PudKjZNSXn4Jm2CKRdBKzSLsFWl8fMG0Bl6Jk1rMAAAAASUVORK5CYII=);
    background-size: 8px 8px;
    transform: scaleY(.125)
  }

  @media (-webkit-min-device-pixel-ratio: 1.5),
  (min-resolution: 1.5dppx) {
    .vue-treeselect__check-mark {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAYFBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////98JRy6AAAAH3RSTlMAzu4sDenl38fBvo1OMyIdEQrj1cSihX5hYFpHNycIcQOASAAAAF9JREFUGNN9zEcOgDAMRFHTS0LvNfe/JRmHKAIJ/mqeLJn+k9uDtaeUeFnFziGsBucUTirrprfe81RqZ3Bb6hPWeuZwDFOHyf+ig9CCzQ7INBn7bG5kF+QSt13BHNJnF7AaCT4Y+CW7AAAAAElFTkSuQmCC)
    }
  }

  @media (-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 192dpi) {
    .vue-treeselect__check-mark {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAYFBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////98JRy6AAAAH3RSTlMAzu4sDenl38fBvo1OMyIdEQrj1cSihX5hYFpHNycIcQOASAAAAF9JREFUGNN9zEcOgDAMRFHTS0LvNfe/JRmHKAIJ/mqeLJn+k9uDtaeUeFnFziGsBucUTirrprfe81RqZ3Bb6hPWeuZwDFOHyf+ig9CCzQ7INBn7bG5kF+QSt13BHNJnF7AaCT4Y+CW7AAAAAElFTkSuQmCC)
    }
  }

  @media (-webkit-min-device-pixel-ratio: 3),
  (min-resolution: 288dpi) {
    .vue-treeselect__check-mark {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAWlBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////9ZMre9AAAAHXRSTlMA/PiJhGNI9XlEHJB/b2ldV08+Oibk49vPp6QhAYgGBuwAAACCSURBVCjPrdHdDoIwDAXgTWAqCigo/+f9X5OwnoUwtis4V92XNWladUl+rzQPeQJAN2EHxoOnsPn7/oYk8fxBv08Rr/deOH/aZ2Nm8ZJ+s573QGfWKnNuZGzWm3+lv2V3pcU1XQ385/yjmBoM3Z+dXvlbYLLD3ujhTaOM3KaIXvNkFkuSEvYy1LqOAAAAAElFTkSuQmCC)
    }
  }

  .vue-treeselect__checkbox--checked>.vue-treeselect__check-mark {
    opacity: 1;
    transform: scaleY(1)
  }

  .vue-treeselect__checkbox--disabled .vue-treeselect__check-mark {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAMAAADz0U65AAAAP1BMVEUAAADj4+Pf39/h4eHh4eHh4eHk5OTh4eHg4ODi4uLh4eHh4eHg4ODh4eHh4eHg4ODh4eHh4eHp6en////h4eFqcyvUAAAAFHRSTlMAOQfy7bgS5NrBvqOIfXNHMSELAgQ/iFsAAAA2SURBVAjXY4AANjYIzcjMAaVFuBkY+RkEWERYmRjYRXjANAOfiIgIFxNIAa8IpxBEi6AwiAQAK2MBd7xY8csAAAAASUVORK5CYII=)
  }

  @media (-webkit-min-device-pixel-ratio: 1.5),
  (min-resolution: 1.5dppx) {
    .vue-treeselect__checkbox--disabled .vue-treeselect__check-mark {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAXVBMVEUAAADh4eHh4eHh4eHi4uLb29vh4eHh4eHh4eHh4eHh4eHh4eHh4eHi4uLi4uLj4+Pi4uLk5OTo6Ojh4eHh4eHi4uLg4ODg4ODh4eHg4ODh4eHf39/g4OD////h4eEzIk+wAAAAHnRSTlMAzu6/LA3p5eLZx8ONTjYiHRIKooV+YWBaRzEnCANnm5rnAAAAZElEQVQY033P2wqAIAyA4VWaaWrnc/n+j5mbhBjUf7WPoTD47TJb4i5zTr/sRDRHuyFaoWX7uK/RlbctlPEuyI1f4WY9yQINEkf6rzzo8YIzmUFoCs7J1EjeIaa9bXIEmzl8dgOZEAj/+2IvzAAAAABJRU5ErkJggg==)
    }
  }

  @media (-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 192dpi) {
    .vue-treeselect__checkbox--disabled .vue-treeselect__check-mark {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAXVBMVEUAAADh4eHh4eHh4eHi4uLb29vh4eHh4eHh4eHh4eHh4eHh4eHh4eHi4uLi4uLj4+Pi4uLk5OTo6Ojh4eHh4eHi4uLg4ODg4ODh4eHg4ODh4eHf39/g4OD////h4eEzIk+wAAAAHnRSTlMAzu6/LA3p5eLZx8ONTjYiHRIKooV+YWBaRzEnCANnm5rnAAAAZElEQVQY033P2wqAIAyA4VWaaWrnc/n+j5mbhBjUf7WPoTD47TJb4i5zTr/sRDRHuyFaoWX7uK/RlbctlPEuyI1f4WY9yQINEkf6rzzo8YIzmUFoCs7J1EjeIaa9bXIEmzl8dgOZEAj/+2IvzAAAAABJRU5ErkJggg==)
    }
  }

  @media (-webkit-min-device-pixel-ratio: 3),
  (min-resolution: 288dpi) {
    .vue-treeselect__checkbox--disabled .vue-treeselect__check-mark {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAUVBMVEUAAADh4eHh4eHh4eHh4eHi4uLi4uLh4eHh4eHh4eHf39/j4+Ph4eHh4eHh4eHg4ODi4uLh4eHh4eHi4uLh4eHh4eHh4eHh4eHh4eH////h4eF3FMFTAAAAGnRSTlMA+/eJhGhfSHE9JBzz5KaQf3pXT0Xbz0I5AYDw8F0AAAB+SURBVCjPrdHbDoMgEEVRKAii1dZe9fz/hxplTiKIT7qfYCWTEEZdUvOwbckNAD2WHeh3brHW5f5EzGQ+iN+b1Gt6KPvtv16Dn6JX9M9ya3/A1yfu5dlyduL6Hec7mXY6ddXLPP2lpABGZ8PWXfYLTJxZekVhhl7eTX24zZPNKXoRC7zQLjUAAAAASUVORK5CYII=)
    }
  }

  .vue-treeselect__checkbox--unchecked {
    border-color: #e0e0e0;
    background: #fff
  }

  .vue-treeselect__label-container:hover .vue-treeselect__checkbox--unchecked {
    border-color: #039be5;
    background: #fff
  }

  .vue-treeselect__checkbox--checked,
  .vue-treeselect__checkbox--indeterminate,
  .vue-treeselect__label-container:hover .vue-treeselect__checkbox--checked,
  .vue-treeselect__label-container:hover .vue-treeselect__checkbox--indeterminate {
    border-color: #039be5;
    background: #039be5
  }

  .vue-treeselect__checkbox--disabled,
  .vue-treeselect__label-container:hover .vue-treeselect__checkbox--disabled {
    border-color: #e0e0e0;
    background-color: #f7f7f7
  }

  .vue-treeselect__label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: table-cell;
    padding-left: 5px;
    max-width: 100%;
    vertical-align: middle;
    cursor: inherit
  }

  [dir=rtl] .vue-treeselect__label {
    padding-left: 0;
    padding-right: 5px
  }

  .vue-treeselect__count {
    margin-left: 5px;
    font-weight: 400;
    opacity: .6
  }

  [dir=rtl] .vue-treeselect__count {
    margin-left: 0;
    margin-right: 5px
  }

  .vue-treeselect__tip {
    padding-left: 5px;
    padding-right: 5px;
    display: table;
    table-layout: fixed;
    width: 100%;
    color: #757575
  }

  .vue-treeselect__tip-text {
    display: table-cell;
    vertical-align: middle;
    padding-left: 5px;
    padding-right: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    font-size: 12px
  }

  .vue-treeselect__error-tip .vue-treeselect__retry {
    cursor: pointer;
    margin-left: 5px;
    font-style: normal;
    font-weight: 600;
    text-decoration: none;
    color: #039be5
  }

  [dir=rtl] .vue-treeselect__error-tip .vue-treeselect__retry {
    margin-left: 0;
    margin-right: 5px
  }

  .vue-treeselect__icon-container {
    display: table-cell;
    vertical-align: middle;
    width: 20px;
    text-align: center;
    line-height: 0
  }

  .vue-treeselect--single .vue-treeselect__icon-container {
    padding-left: 5px
  }

  [dir=rtl] .vue-treeselect--single .vue-treeselect__icon-container {
    padding-left: 0;
    padding-right: 5px
  }

  .vue-treeselect__icon-warning {
    display: block;
    margin: auto;
    border-radius: 50%;
    position: relative;
    width: 12px;
    height: 12px;
    background: #fb8c00
  }

  .vue-treeselect__icon-warning:after {
    display: block;
    position: absolute;
    content: "";
    left: 5px;
    top: 2.5px;
    width: 2px;
    height: 1px;
    border: 0 solid #fff;
    border-top-width: 5px;
    border-bottom-width: 1px
  }

  .vue-treeselect__icon-error {
    display: block;
    margin: auto;
    border-radius: 50%;
    position: relative;
    width: 12px;
    height: 12px;
    background: #e53935
  }

  .vue-treeselect__icon-error:after,
  .vue-treeselect__icon-error:before {
    display: block;
    position: absolute;
    content: "";
    background: #fff;
    transform: rotate(45deg)
  }

  .vue-treeselect__icon-error:before {
    width: 6px;
    height: 2px;
    left: 3px;
    top: 5px
  }

  .vue-treeselect__icon-error:after {
    width: 2px;
    height: 6px;
    left: 5px;
    top: 3px
  }

  .vue-treeselect__icon-loader {
    display: block;
    margin: auto;
    position: relative;
    width: 12px;
    height: 12px;
    text-align: center;
    -webkit-animation: vue-treeselect-animation-rotate 1.6s linear infinite;
    animation: vue-treeselect-animation-rotate 1.6s linear infinite
  }

  .vue-treeselect__icon-loader:after,
  .vue-treeselect__icon-loader:before {
    border-radius: 50%;
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    opacity: .6;
    -webkit-animation: vue-treeselect-animation-bounce 1.6s ease-in-out infinite;
    animation: vue-treeselect-animation-bounce 1.6s ease-in-out infinite
  }

  .vue-treeselect__icon-loader:before {
    background: #039be5
  }

  .vue-treeselect__icon-loader:after {
    background: #b3e5fc;
    -webkit-animation-delay: -.8s;
    animation-delay: -.8s
  }

  .vue-treeselect__menu-placeholder {
    display: none
  }

  .vue-treeselect__portal-target {
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    height: 0;
    width: 0;
    padding: 0;
    margin: 0;
    border: 0;
    overflow: visible;
    box-sizing: border-box
  }
</style>