<template>
  <div>
    <KTCard v-bind:title="title">
      <!--页面标题-->
      <template v-slot:title>
        <h3 class="card-title">
          <b-icon icon="blockquote-right" class="mx-3"></b-icon>
          支付接口管理
        </h3>
      </template>

      <!--操作按钮组-->
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <b-button
            variant="outline-primary"
            class="mx-2"
            @click="goBack()"
          >
            <i class="flaticon2-left-arrow"></i>
            返回
          </b-button>

          <!--按钮组-->
          <b-button variant="primary" @click="onSubmit()" class="mx-2">
            <i class="flaticon2-fax"></i>
            保存
          </b-button>

          <!--按钮组-->
          <b-button variant="danger" @click="onReset()" class="mx-2">
            <i class="flaticon2-circular-arrow"></i>
            重置
          </b-button>
          <!--按钮组-->
        </div>
      </template>

      <!--数据表格-->
      <template v-slot:body>
        <div
          style="
            width: 70% !important;
            min-height: 70vh;
            margin: auto !important;
          "
        >
          <b-form class="form" id="payInterfaceForm">
            <b-row class="mt-0">
              <b-col class="pt-0 pb-2">
                <b-form-group label="通道">
                  <b-form-select name="channelId" v-model="formModel.channelId">
                    <option
                      :key="item.channelId"
                      v-for="item in channels"
                      :value="item.channelId"
                    >
                      {{ item.channelName }}
                    </option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col class="pt-0 pb-2">
                <b-form-group label="别名:">
                  <b-form-input
                    type="text"
                    name="channelAlias"
                    v-model="formModel.channelAlias"
                    placeholder="请输入通道别名..."
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-0">
              <b-col class="pt-0 pb-2">
                <b-form-group label="通道网关地址:">
                  <b-form-input
                    type="text"
                    name="apiUrl"
                    v-model="formModel.apiUrl"
                    placeholder="请输入通道接口地址..."
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col class="pt-0 pb-2">
                <b-form-group label="接口Bean:">
                  <b-form-input
                    type="text"
                    name="beanName"
                    v-model="formModel.beanName"
                    placeholder="请输入接口Bean..."
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-0">
              <b-col class="pt-0 pb-2">
                <b-form-group label="通道商户号:">
                  <b-form-input
                    type="text"
                    name="appId"
                    v-model="formModel.appId"
                    placeholder="请输入通道账号..."
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col class="pt-0 pb-2">
                <b-form-group label="通道key:">
                  <b-form-input
                    type="text"
                    name="appKey"
                    v-model="formModel.appKey"
                    placeholder="请输入通道key..."
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-0">
              <b-col class="pt-0 pb-2">
                <b-form-group label="通道密钥:">
                  <b-form-input
                    type="text"
                    name="appSecret"
                    v-model="formModel.appSecret"
                    placeholder="请输入通道密钥..."
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col class="pt-0 pb-2">
                <b-form-group label="白名单:">
                  <b-form-input
                    type="text"
                    name="whitelist"
                    v-model="formModel.whitelist"
                    placeholder="请输入白名单..."
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-0">
              <b-col class="pt-0 pb-2">
                <b-form-group label="公共密钥:">
                  <b-form-input
                    type="text"
                    name="publicKey"
                    v-model="formModel.publicKey"
                    placeholder="请输入公共密钥..."
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col class="pt-0 pb-2">
                <b-form-group label="私有密钥:">
                  <b-form-input
                    type="text"
                    name="privateKey"
                    v-model="formModel.privateKey"
                    placeholder="请输入私有密钥..."
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mt-0">
              <b-col class="pt-0 pb-2">
                <b-form-group label="通道登录账号:">
                  <b-form-input
                    type="text"
                    name="loginAcccount"
                    v-model="formModel.loginAcccount"
                    placeholder="请输入通道登录账号..."
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col class="pt-0 pb-2">
                <b-form-group label="通道登录密码:">
                  <b-form-input
                    type="text"
                    name="loginPwd"
                    v-model="formModel.loginPwd"
                    placeholder="请输入通道登录密码..."
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mt-0">
              <b-col class="pt-0 pb-2">
                <b-form-group label="通道登录KEY:">
                  <b-form-input
                    type="text"
                    name="loginKey"
                    v-model="formModel.loginKey"
                    placeholder="请输入通道登录KEY..."
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col class="pt-0 pb-2">
                <b-form-group label="通道费率:">
                  <b-form-input
                    type="text"
                    name="feeRate"
                    v-model="formModel.feeRate"
                    placeholder="请输入通道费率..."
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mt-0">
              <b-col class="pt-0 pb-2">
                <b-form-group label="最小交易金额:">
                  <b-form-input
                    type="text"
                    name="minAmount"
                    v-model="formModel.minAmount"
                    placeholder="请输入最小交易金额..."
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col class="pt-0 pb-2">
                <b-form-group label="最大交易金额:">
                  <b-form-input
                    type="text"
                    name="maxAmount"
                    v-model="formModel.maxAmount"
                    placeholder="请输入最大交易金额..."
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mt-0">
              <b-col class="pt-0 pb-2">
                <b-form-group label="优先顺序:">
                  <b-form-input
                    type="text"
                    name="sort"
                    v-model="formModel.sort"
                    placeholder="请输入优先顺序..."
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col class="pt-0 pb-2">
                <b-form-group label="代付限额:">
                  <b-form-input
                    type="text"
                    name="quotaAmount"
                    v-model="formModel.quotaAmount"
                    placeholder="请输入代付限额..."
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mt-0">
              <b-col class="pt-0 pb-0">
                <b-form-group label="支付类型:">
                  <TreeSelect
                    :select.sync="formModel.types"
                    :datas.sync="payTypes"
                    nameKey="name"
                    valueKey="type"
                    ref="payTypeSelector"
                  ></TreeSelect>
                </b-form-group>
              </b-col>

              <b-col class="pt-0 pb-0">
                <b-form-group label="状态:">
                  <v-radio-group
                    class="mt-0"
                    row
                    name="status"
                    v-model="formModel.status"
                    :mandatory="false"
                  >
                    <v-radio label="正常" :value="1"></v-radio>
                    <v-radio label="禁用" :value="0"></v-radio>
                  </v-radio-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mt-0">
              <b-col class="pt-0 pb-2">
                <b-form-group label="是否替换收银台:">
                  <v-radio-group
                    class="mt-0"
                    row
                    name="replaceCashier"
                    v-model="formModel.replaceCashier"
                    :mandatory="false"
                  >
                    <v-radio label="是" :value="1"></v-radio>
                    <v-radio label="否" :value="0"></v-radio>
                  </v-radio-group>
                </b-form-group>
                
              </b-col>
              <b-col class="pt-0 pb-2">
               <b-form-group label="渠道收银台地址:">
                  <b-form-input
                    type="text"
                    name="cashierHost"
                    v-model="formModel.cashierHost"
                    placeholder="渠道收银台地址..."
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-0">
              <b-col class="pt-0 pb-2">
                <b-form-group label="通道扩展配置:">
                  <b-form-textarea type="text" :rows="1"
                    name="channelConfigs"
                    v-model="formModel.channelConfigs"
                    placeholder="通道扩展配置..."
                  >
                  </b-form-textarea>
                </b-form-group>
                
              </b-col>
              <b-col class="pt-0 pb-2">
               <b-form-group label="tg群id:">
                  <b-form-input
                    type="text"
                    name="tgChatGroupId"
                    v-model="formModel.tgChatGroupId"
                    placeholder="tg群id..."
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

          </b-form>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import KTCard from "@/view/content/Card.vue";
import TreeSelect from "@/view/pages/common/plugins/Treeselect";

import KTUtil from "@/assets/js/components/util";
import FileUpload from "@/view/pages/oms_pages/plugin/FileUpload";
import ApiService from "@/core/services/api.service";
// FormValidation plugins
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import Swal from "sweetalert2";

export default {
  name: "Edit",
  components: {
    KTCard,
    FileUpload,
    TreeSelect,
  },
  data() {
    return {
      channels: [],
      payTypes: [],
      formModel: {
        interfaceId: "",
        types: [],
      },
    };
  },

  created() {
    let interfaceId = this.$route.query.interfaceId;
    this.getChannels();
    this.getPayTypes();
    this.getInterfaceInfo(interfaceId);
  },

  mounted() {
    let payInterfaceForm = KTUtil.getById("payInterfaceForm");
    this.fv = formValidation(payInterfaceForm, {
      fields: {
        channelId: {
          validators: {
            notEmpty: {
              message: "请选择通道!",
            },
          },
        },
        apiUrl: {
          validators: {
            notEmpty: {
              message: "通道接口地址能为空!",
            },
          },
        },

        appId: {
          validators: {
            notEmpty: {
              message: "通道账号不能为空!",
            },
          },
        },
        appKey: {
          validators: {
            notEmpty: {
              message: "通道key不能为空!",
            },
          },
        },
        appSecret: {
          validators: {
            notEmpty: {
              message: "通道密钥不能为空!",
            },
          },
        },

        feeRate: {
          validators: {
            notEmpty: {
              message: "通道费率不能为空!",
            },
          },
        },

        minAmount: {
          validators: {
            notEmpty: {
              message: "最小交易金额不能为空!",
            },
          },
        },
        maxAmount: {
          validators: {
            notEmpty: {
              message: "最大交易金额不能为空!",
            },
          },
        },
        sort: {
          validators: {
            notEmpty: {
              message: "排序不能为空!",
            },
          },
        },
        status: {
          validators: {
            notEmpty: {
              message: "请选择状态!",
            },
          },
        },
        replaceCashier: {
          validators: {
            notEmpty: {
              message: "请选是否替换收银台!",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap(),
      },
    });
  },

  methods: {
   
    getChannels() {
      this.channels = [];
      let that = this;
      let params = {
        noPaging: true,
      };

      ApiService.query("/api/channel/manage/query_list", {
        params: params,
      }).then(({ data }) => {
        that.channels = data.result.data;
      });
    },

    getPayTypes() {
      this.payTypes = [];
      let that = this;
      ApiService.query("/api/common/pay_types", {}).then(({ data }) => {
        that.payTypes = data;
      });
    },

    getInterfaceInfo(interfaceId) {
      let that = this;
      if (interfaceId != null && interfaceId != undefined) {
        ApiService.query("/api/channel/interface/manage/query_by_id", {
          params: {
            interfaceId: interfaceId,
          },
        }).then(({ data }) => {
          that.formModel = data.result;
          that.$refs["payTypeSelector"].updateSelect(that.formModel.types);
        });
      }
    },

    onSubmit() {
      let that = this;
      this.fv.validate().then(function (status) {
        if (status === "Valid") {
          ApiService.post(that.submitUrl, that.formModel).then(({ data }) => {
            if (data.success) {
              Swal.fire({
                title: "",
                text: "保存成功！",
                icon: "success",
                confirmButtonClass: "btn btn-success",
                heightAuto: false,
                timer: 1500,
              });
              that.goBack();
              that.$refs["my-modal"].hide();
            } else {
              Swal.fire({
                title: "保存失败！",
                text: data.message,
                icon: "warning",
                confirmButtonClass: "btn btn-success",
                heightAuto: false,
                timer: 3000,
              });
            }
          });
        }
      });
    },

    onReset() {
      let interfaceId = this.formModel.interfaceId;
      this.formModel = {};
      this.formModel.interfaceId = interfaceId;
    },

    addItem() {
      this.formModel.materials.push({
        id: "",
        name: "",
      });
    },
    deleteItem(material) {
      let that = this;
      Swal.fire({
        title: "确定删除?",
        icon: "warning",
        buttons: true,
        showConfirmButton: true,
        confirmButtonText: "确定",
        showCancelButton: true,
        cancelButtonText: "取消",
        dangerMode: true,
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          let index = that.formModel.materials.indexOf(material);
          that.formModel.materials.splice(index, 1);
        }
      });
    },
    goBack() {
       this.$router.push({
        path: "/channel/interface/list",
      });
    },
  },
  computed: {
    submitUrl() {
      return this.formModel.interfaceId == null ||
        this.formModel.interfaceId == ""
        ? "/api/channel/interface/manage/create"
        : "/api/channel/interface/manage/update";
    },
  },

  watch: {},
};
</script>